import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

export const Title = ({ title, isParentPresent }) => {
	const [animationState, setAnimationState] = useState("show");

	useEffect(() => {
		let timeoutId;
		if (!isParentPresent) {
			timeoutId = setTimeout(() => {
				setAnimationState("exit");
			}, 10);
		} else {
			setAnimationState("show");
		}
		return () => clearTimeout(timeoutId);
	}, [isParentPresent]);

	const variants = {
		initial: { y: 20, opacity: 0 },
		show: { y: 0, opacity: 1 },
		exit: { y: 20, opacity: 0 },
	};

	return (
		<motion.div
			variants={variants}
			initial="initial"
			animate={animationState}
			transition={{ type: "spring", stiffness: 100, delay: 0.05 }}>
			<StyledH2>{title}</StyledH2>
		</motion.div>
	);
};

const StyledH2 = styled.h2`
	font-family: "Antonio", sans-serif;
	font-size: 2.5rem;
	margin-bottom: 0.35em;
	color: white;
	@media all and (max-width: 992px) {
		font-size: 1.75rem;
	}
	@media all and (max-width: 767px) {
		font-size: 1rem;
	}
`;
