import React, { useState, useEffect, createContext, useRef } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

import { Calculator } from "../molecules/Calculator";
import { StatGrid } from "../organisms/StatGrid";
import { CTALink } from "../atoms/CTALink";

import fetchGoogleSheetsData from "../../lib/fetchGoogleSheetsData";
import getSvg from "../../lib/getSvg";

import { getScroll, restartScroll } from "../../lib/scroll";

// Create a context
export const CalculatorContext = createContext();

// Create a simple React component
export const ImpactCalculator = () => {
	const [userInteracted, setUserInteracted] = useState(false);
	const [value, setValue] = useState("");
	const [totalTimeToUpdate, setTotalTimeToUpdate] = useState(1500); // milliseconds
	const [sheetData, setSheetData] = useState([]);
	const [svgContent, setSvgContent] = useState("");
	const animatedParagraphContainerRef = useRef(null);

	// CaclulatorData is a localized variable from wp_localize_script in enqueue.php
	const { googlesheetCredentials, ctaLink, stylesheetDirectoryUri } = CalculatorData;
	const { sheetId, clientId, apiKey } = googlesheetCredentials;

	const [isScrollUpdated, setIsScrollUpdated] = useState(false);

	useEffect(() => {
		fetchGoogleSheetsData(sheetId, apiKey, clientId)
			.then((data) => {
				setSheetData(data);
				// console.log(data);
			})
			.catch((error) => {
				console.error("Error fetching Google Sheets data:", error);
			});

		getSvg("handshake-heart")
			.then((svg) => {
				setSvgContent(svg);
				// console.log(svg);
			})
			.catch((error) => {
				console.error("Error fetching SVG:", error);
			});

		const animatedParagraphContainer = animatedParagraphContainerRef.current;
		if (animatedParagraphContainer) {
			console.log("animatedParagraphContainer: ", animatedParagraphContainer);
			// get the width and height of the child p element of the ref
			const { width, height } = animatedParagraphContainer.querySelector("p").getBoundingClientRect();
			// set the height of the parent container to the height of the child p element
			animatedParagraphContainer.style.height = `${height}px`;
			animatedParagraphContainer.style.width = `${width}px`;
		}
	}, [sheetId, clientId, apiKey]);

	const onChange = (e) => {
		const rawValue = e.target.value.replace(/,/g, "");
		if (isNaN(rawValue)) {
			return;
		}

		setTimeout(() => {
			restartScroll();
		}, totalTimeToUpdate);

		setUserInteracted(true);
		const formattedValue = Number(rawValue).toLocaleString();

		// if the value is 0 or empty, clear the input
		if (rawValue === "0" || rawValue === "") {
			setValue("");
			return;
		}

		setValue(formattedValue);
	};

	const commonPVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0, height: "auto" },
		exit: { opacity: 0, y: 0, height: 0 },
	};

	const ctaVariants = {
		hidden: { opacity: 0, y: 20, transition: { duration: 0.5, delay: 0 } },
		visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 1 } },
		exit: { opacity: 0, y: 0, transition: { duration: 0.5, delay: 0 } },
	};

	if (sheetData.length === 0) {
		return (
			<StyledDiv>
				<header>
					<h1 className="text-xl">Impact Calculator</h1>
				</header>
				<p>Loading...</p>
			</StyledDiv>
		);
	}

	return (
		<CalculatorContext.Provider value={{ value, onChange, sheetData }}>
			<StyledDiv>
				<header>
					<h1 className="text-xl">Impact Calculator</h1>
					<AnimatePresence>
						{!userInteracted && (
							<motion.p
								className="instruction text-lg"
								variants={commonPVariants}
								initial="hidden"
								animate="visible"
								exit="exit">
								Enter a number below to see the impact of your donation!
							</motion.p>
						)}
					</AnimatePresence>
				</header>
				<Calculator userInteracted={userInteracted} />
				<AnimatePresence>
					{(value.length !== 0 || value === "0") && (
						<StyledCTADiv>
							<motion.p
								variants={commonPVariants}
								initial="hidden"
								transition={{ duration: 1, delay: 0.2 }}
								animate="visible"
								exit="exit">
								Here's the potential impact of a ${value} donation.
							</motion.p>
						</StyledCTADiv>
					)}
				</AnimatePresence>
				<StatGrid />
				{(value.length !== 0 || value === "0") && (
					<StyledCTADiv>
						<AnimatePresence>
							{ctaLink && (
								<CTALink
									variants={ctaVariants}
									href={ctaLink.url}
									target={ctaLink.target == "_blank" ? "_blank" : "_self"}
									className="fancy-btn icon-btn primary-btn"
									svgIcon={svgContent}
									title={ctaLink.title}
								/>
							)}
						</AnimatePresence>
					</StyledCTADiv>
				)}
			</StyledDiv>
		</CalculatorContext.Provider>
	);
};

const StyledDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	text-align: center;
	color: #eee;
	header {
		position: relative;
		width: 100%;
		h1 {
			color: var(--color-muted-blue);
			margin-bottom: 1em;
		}
		.instruction {
			position: relative;
			width: 100%;
			height: auto;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			@media all and (max-width: 767px) {
				font-size: 0.875rem;
				line-height: normal;
			}
		}
	}
`;
const StyledCTADiv = styled.div`
	display: flex;
	margin-bottom: 2em;
	align-items: center;
`;
