import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { StatBox } from "../molecules/StatBox";
import { CalculatorContext } from "../templates/ImpactCalculator";

export const StatGrid = () => {
	const { value, sheetData } = useContext(CalculatorContext);
	const [statBoxDisplay, setStatBoxDisplay] = useState([false, false, false]);
	const [expandedState, setExpandedState] = useState(false);
	const [expandedStatBox, setExpandedStatBox] = useState(0);

	const onExpand = (e) => {
		setExpandedState((prevIsExpanded) => {
			const newIsExpanded = !prevIsExpanded;
			if (newIsExpanded) {
				const button = e.target.closest("button");
				const statBoxId = button.getAttribute("data-stat-box-id");
				console.log("statBoxId: ", statBoxId);
				setExpandedStatBox(parseInt(statBoxId));
			} else {
				setExpandedStatBox(0);
			}
			return newIsExpanded;
		});
	};

	// newer one, hang on to this for a sec
	useEffect(() => {
		const newStatBoxDisplay = calculateDisplayArray(value);
		setStatBoxDisplay(newStatBoxDisplay);
		const handler = setTimeout(() => {
			setStatBoxDisplay(newStatBoxDisplay);
		}, 750); // debounce delay
		return () => {
			clearTimeout(handler);
		};
		// console.log("statBoxDisplay: ", newStatBoxDisplay);
	}, [value, sheetData, expandedState]);

	// old one, hang on to this for a sec
	// useEffect(() => {
	// 	setStatBoxDisplay(calculateDisplayArray(value));
	// 	const handler = setTimeout(() => {
	// 		setStatBoxDisplay(calculateDisplayArray(value));
	// 		// scroll.update();
	// 	}, 750); // debounce delay

	// 	return () => {
	// 		clearTimeout(handler);
	// 	};
	// }, [value, sheetData]);

	const calculateDisplayArray = (value) => {
		// value may have decimals, so be sure to convert it to an integer
		// remove commas first
		value = value.toString().replace(/,/g, "");
		// then convert to an integer
		value = parseInt(value);
		const displayArray = [value % 5 >= 1, value % 10 >= 5, value >= 10];
		return displayArray;
	};

	return (
		<StyledDiv className={`${expandedState && "grid-expanded-state"}`}>
			<AnimatePresence>
				{statBoxDisplay[0] === true && (
					<StatBox
						className={`box ${expandedStatBox === 1 && "expanded"}`}
						header="$1.00/week"
						dollarAmount={1}
						onExpand={onExpand}
						isExpanded={expandedStatBox === 1}
					/>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{statBoxDisplay[1] === true && (
					<StatBox
						className={`box ${expandedStatBox === 5 && "expanded"}`}
						header="$5/week"
						dollarAmount={5}
						onExpand={onExpand}
						isExpanded={expandedStatBox === 5}
					/>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{statBoxDisplay[2] === true && (
					<StatBox
						className={`box ${expandedStatBox === 10 && "expanded"}`}
						header="$10/week"
						dollarAmount={10}
						onExpand={onExpand}
						isExpanded={expandedStatBox === 10}
					/>
				)}
			</AnimatePresence>
			{/* {statBoxDisplay.map((display, index) => (
				<p>{display == true ? "true" : "false"}</p>
			))} */}
		</StyledDiv>
	);
};

const StyledDiv = motion(styled.div`
	position: relative;
	/* display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-template-rows: repeat(auto-fit, minmax(200px, 1fr)); */
	display: flex;
	justify-content: center;
	/* align-items: center; */
	gap: 1rem;
	width: 100%;
	margin-bottom: 4em;
	/* min-height: 400px; */
	@media all and (max-width: 992px) {
		flex-wrap: wrap;
	}
	&.grid-expanded-state {
		.box:not(.expanded) {
			opacity: 0.25;
		}
	}
`);
