import React, { useContext } from "react";
import styled from "styled-components";
import { CalculatorContext } from "../templates/ImpactCalculator";

export const Input = () => {
	const { value, onChange } = useContext(CalculatorContext);

	return (
		<StyledDiv>
			<label>$</label>
			<input autoFocus type="text" value={value} placeholder="0" onChange={onChange} />
		</StyledDiv>
	);
};

const StyledDiv = styled.div`
	display: flex;
	align-items: flex-end;
	input,
	label {
		font-family: "Antonio", sans-serif;
		color: #fff;
	}
	label {
		font-size: 2.5em;
		margin-bottom: 0.35em;
	}
	input {
		text-align: center;
		font-size: 3.5em;
		border: none;
		border-bottom: 2px solid #fff;
		padding-bottom: 0.125em;
		&:focus {
			outline: none;
		}
	}
	@media all and (max-width: 767px) {
		label {
			font-size: 1.5em;
		}
		input {
			font-size: 2.5em;
		}
	}
`;
