export default async (svgName) => {
	// check for `stylesheetDirectoryUri` either from wp localized script variable or from the window and if it doesn't exist, just set it to ''
	const stylesheetDirectoryUri =
		(CalculatorData && CalculatorData.stylesheetDirectoryUri) || window.stylesheetDirectoryUri || "";

	const distPath = "/dist/images/";
	const filePath = `${stylesheetDirectoryUri}${distPath}${svgName}.svg`;

	try {
		const response = await fetch(filePath);
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const svgContent = await response.text();
		return svgContent;
	} catch (error) {
		console.error("Error fetching SVG:", error);
		return "<!-- No SVG found -->";
	}
};
