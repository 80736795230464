import React from "react";
import styled from "styled-components";

export const SubTitle = ({ title, children }) => {
	// if children isn't text, return null
	if (typeof children !== "string") {
		return null;
	}

	return (
		<StyledP className="sub-title" title={children}>
			{children}
		</StyledP>
	);
};

const StyledP = styled.p`
	font-size: 1em;
	font-weight: bold;
	margin: 0;
	color: var(--color-muted-blue);
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	white-space: normal;
	max-height: 3em; /* assuming line-height is 1.5em */
	line-height: 1.5em;
	@media all and (max-width: 767px) {
		font-size: 0.75em;
		margin-bottom: 0.5em;
	}
`;
