import React, { useState, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { motion, AnimatePresence, usePresence } from "framer-motion";

import { CalculatorContext } from "../templates/ImpactCalculator";

export const BorderBox = ({
	animationStyle = "outline", // sparkWave, outline
	rounded = false,
	borderAnimDuration = 0.5,
	borderAnimDelay = 0,
	borderAnimRepeat = 0,
	dashLength = 150,
	gapLength = 1200,
	isParentPresent = true,
	userInteracted,
	extraCheatPerimeterLength = 0, // nudge this value from the props attribute level to cheat the length if necessary, its hacky but it does the job for now
	isExpanded = false,
}) => {
	const [animationState, setAnimationState] = useState("show");
	const [borderAnimattionRunning, setBorderAnimationRunning] = useState(false);
	const [isMounted, setIsMounted] = useState(false);
	const [calculatedGapLength, setCalculatedGapLength] = useState(gapLength);
	const [strokeDashArray, setStrokeDashArray] = useState(0);
	const svgRef = useRef(null);

	// switch on for if we decide to use state instead for the border calculations
	// const [totalLength, setTotalLength] = useState(dashLength + calculatedGapLength);
	// const [dashArray, setDashArray] = useState(`${dashLength} ${calculatedGapLength}`);
	// const [dashOffset, setDashOffset] = useState(totalLength + 50);
	// const [initialOffset, setInitialOffset] = useState(totalLength + totalLength / 17 + 500);
	// const [targetOffset, setTargetOffset] = useState(totalLength - (totalLength - totalLength / 17));
	// const [sparkDashLength, setSparkDashLength] = useState(dashLength * 0.1);
	// const [sparkGapLength, setSparkGapLength] = useState(calculatedGapLength + dashLength * 0.9);
	// const [sparkDashArray, setSparkDashArray] = useState(`${sparkDashLength} ${sparkGapLength}`);
	// const [sparkDashOffset, setSparkDashOffset] = useState(dashOffset - dashLength);

	useLayoutEffect(() => {
		// to do - remove this timeout and use a better method to wait for the data to fill before calculating
		const borderCalculationInterval = setTimeout(() => {
			const svgContainer = svgRef.current;
			if (svgContainer) {
				const { width, height } = svgContainer.getBoundingClientRect();
				const borderWidth = 4;
				const perimeter = 2 * (width + height) - 4 * borderWidth + extraCheatPerimeterLength;
				setCalculatedGapLength(perimeter - dashLength);
				// setTotalLength(dashLength + calculatedGapLength);
				// setDashArray(`${dashLength} ${calculatedGapLength}`);
				// setDashOffset(totalLength + 50);
				// setInitialOffset(totalLength + totalLength / 17 + 500);
				// setTargetOffset(totalLength - (totalLength - totalLength / 17));
				// setSparkDashLength(dashLength * 0.1);
				// setSparkGapLength(calculatedGapLength + dashLength * 0.9);
				// setSparkDashArray(`${sparkDashLength} ${sparkGapLength}`);
				// setSparkDashOffset(dashOffset - dashLength);
			}

			if (!isMounted && isParentPresent) {
				setIsMounted(true);
			}

			if (!userInteracted && animationStyle === "sparkWave") {
				const interval = setInterval(() => {
					setAnimationState("show");
					setBorderAnimationRunning(false);
					setTimeout(() => setIsMounted(true), 50);
				}, 3000);

				return () => clearInterval(interval);
			}

			// if (animationStyle === "outline") {
			// 	const interval = setInterval(() => {
			// 		setStrokeDashArray(0); // make sure we don't have to worry about the gap once we've animated it
			// 	}, 1500);
			// 	return () => clearInterval(interval);
			// }
		}, borderAnimDelay * 1000);

		return () => {
			clearTimeout(borderCalculationInterval);
		};
	}, [isMounted, , isParentPresent, userInteracted]);

	// bordder calculations
	const totalLength = dashLength + calculatedGapLength;
	const dashArray = `${dashLength} ${calculatedGapLength}`;
	const dashOffset = totalLength + 50;
	const initialOffset = totalLength + totalLength / 17 + 500;
	const targetOffset = totalLength - (totalLength - totalLength / 17);

	const sparkDashLength = dashLength * 0.1;
	const sparkGapLength = calculatedGapLength + dashLength * 0.9;
	const sparkDashArray = `${sparkDashLength} ${sparkGapLength}`;
	const sparkDashOffset = dashOffset - dashLength;

	const commonRectProps = {
		width: "100%",
		height: "100%",
		fill: "none",
		strokeWidth: "2",
		rx: rounded ? "15" : "0",
		ry: rounded ? "15" : "0",
	};

	const transitionProps = {
		duration: borderAnimDuration,
		ease: "linear",
		repeat: borderAnimRepeat,
	};

	const opacityAnimation = borderAnimRepeat !== Infinity ? { opacity: [1, 1, 0] } : {};
	let currentIteration = 0;

	const animationProps = {
		animate: {
			strokeDashoffset: [initialOffset, targetOffset],
			...opacityAnimation,
		},
		transition: {
			...transitionProps,
			onComplete:
				borderAnimRepeat !== Infinity
					? () => {
							currentIteration++;
							if (currentIteration >= borderAnimRepeat && svgRef.current) {
								svgRef.current.style.opacity = 0;
							}
					  }
					: undefined,
		},
	};

	const sparkAnimationProps = {
		...commonRectProps,
		stroke: "white",
		strokeDasharray: sparkDashArray,
		strokeDashoffset: sparkDashOffset - dashLength,
		animate: {
			strokeDashoffset: [initialOffset - dashLength, targetOffset - dashLength],
			...opacityAnimation,
		},
		transition: {
			...transitionProps,
			onComplete:
				borderAnimRepeat !== Infinity
					? () => {
							if (svgRef.current) {
								svgRef.current.style.opacity = 0;
							}
					  }
					: undefined,
		},
	};

	const outlineAnimationProps = {
		...commonRectProps,
		stroke: "#fff",
		strokeDasharray: `${totalLength} 0`,
		strokeDashoffset: 0,
		animate: {
			strokeDashoffset: [totalLength, 0],
		},
		transition: {
			duration: borderAnimDuration,
			ease: "linear",
			repeat: 0,
			delay: borderAnimDelay,
		},
	};

	const svgRootOutlineProps = {
		animate: {
			opacity: [0, 1],
			y: ["10%", "0%"],
		},
		transition: {
			type: "spring",
			damping: 12,
			stiffness: 100,
			delay: 0.25,
		},
		exit: {
			opacity: 0,
			y: "10%",
		},
	};

	if (!calculatedGapLength) {
		return null;
	}

	return (
		<StyledSvgContainerDiv className={`${isExpanded && "isExpanded"}`}>
			<AnimatePresence>
				{isMounted && isParentPresent && (
					<StyledSvg ref={svgRef} id="border-svg" {...svgRootOutlineProps}>
						<StyledRect
							{...commonRectProps}
							{...(animationStyle === "outline" ? outlineAnimationProps : animationProps)}
							strokeDasharray={
								animationStyle === "outline" ? `${totalLength + 100} ${totalLength}` : dashArray
							}
							strokeDashoffset={animationStyle === "outline" ? 0 : dashOffset}
						/>
						{animationStyle === "sparkWave" && (
							<>
								<StyledRectBrighterFuse {...sparkAnimationProps} style={{ filter: "blur(10px)" }} />
								<StyledRectBrighterFuse {...sparkAnimationProps} style={{ filter: "blur(5px)" }} />
								<StyledRectBrighterFuse {...sparkAnimationProps} style={{ filter: "blur(1px)" }} />
							</>
						)}
					</StyledSvg>
				)}
			</AnimatePresence>
		</StyledSvgContainerDiv>
	);
};

const StyledSvgContainerDiv = styled.div`
	z-index: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: visible;
	border: none;
	&.isExpanded {
		svg {
			rect {
				stroke-dasharray: 0 !important;
			}
		}
	}
`;

const StyledSvg = motion(styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: visible;
	border: none;
`);

const StyledRect = motion(styled.rect`
	fill: none;
	stroke: #fff;
	stroke-width: 3;
	opacity: ${(props) => (props.animationStyle === "sparkWave" ? 0.25 : 1)};
`);

const StyledRectBrighterFuse = motion(styled.rect`
	fill: none;
	stroke: #fff;
	stroke-width: 4;
	/* transform: scale(1.001); */
	/* transform-origin: center; */
`);
