import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import { ImpactCalculator } from "./react-components/templates/ImpactCalculator";

// DOM load event
document.addEventListener("DOMContentLoaded", function () {
	// Render the React component to the root element
	const rootElement = document.getElementById("impact-calculator");

	if (rootElement) {
		// create root
		const root = createRoot(rootElement);
		root.render(<ImpactCalculator />);
	}
});
