import PublicGoogleSheetsParser from "public-google-sheets-parser";

async function fetchGoogleSheetsData(sheetId, apiKey, clientId) {
	const spreadsheetId = sheetId;
	const parser = new PublicGoogleSheetsParser(spreadsheetId, {
		sheetName: "live-website-isolated-data(don't touch)",
		useFormat: true,
	});

	// clean up the data first
	const data = await parser.parse();
	return data;
	// return "shraw shank redemption";
}

export default fetchGoogleSheetsData;

// async function fetchGoogleSheetsData(sheetId, jsonKeyPath) {
// 	const response = await fetch(jsonKeyPath);
// 	const credentials = await response.json();

// 	console.log("sheetId", sheetId);
// 	console.log("jsonKeyPath", jsonKeyPath);

// 	const serviceAccountAuth = new JWT({
// 		email: credentials.client_email,
// 		key: credentials.private_key,
// 		scopes: ["https://www.googleapis.com/auth/spreadsheets"],
// 	});

// 	const doc = new GoogleSpreadsheet(sheetId, serviceAccountAuth);

// 	await doc.loadInfo(); // Loads document properties and worksheets
// 	console.log(doc.title);

// 	// const sheet = doc.sheetsByIndex[0]; // Get the first sheet
// 	// const rows = await sheet.getRows(); // Get all rows

// 	// return rows.map((row) => row._rawData); // Return raw data
// }

// export default fetchGoogleSheetsData;
