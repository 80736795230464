import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { Input } from "../atoms/Input";
import { BorderBox } from "../atoms/BorderBox";

export const Calculator = ({ userInteracted }) => {
	return (
		<StyledDiv>
			<motion.div
				initial={{ y: 20, opacity: 0 }}
				animate={{ y: 0, opacity: 1 }}
				transition={{ type: "spring", stiffness: 100 }}>
				<SvgWrapper>
					<BorderBox
						rounded={true}
						animationStyle="sparkWave"
						borderAnimDuration={1.25}
						borderAnimDelay={0.0}
						userInteracted={userInteracted}
					/>
					<Input autoFocus={true} />
				</SvgWrapper>
			</motion.div>
		</StyledDiv>
	);
};

const StyledDiv = styled.div`
	/* margin-bottom: 5em; */
	h2 {
		color: #fff;
	}
`;

const SvgWrapper = styled.div`
	position: relative;
	display: inline-block;
	margin-top: 20px;
	min-width: 400px;
	max-width: 100%;
	min-height: 200px;
	padding: 2em;
	input {
		position: relative;
		z-index: 1;
		width: 100%;
		height: 100%;
	}
	@media all and (max-width: 767px) {
		margin-top: 5px;
		min-width: unset;
		min-height: 0;
		padding: 0;
	}
`;
