import React, { useState, useEffect } from "react";
import styled from "styled-components";

import getSvg from "../../lib/getSvg";

export const ExpandButton = ({ isExpanded, onClick, dataStatBoxId }) => {
	const [svgContent, setSvgContent] = useState("");

	useEffect(() => {
		getSvg("expand-icon")
			.then((svg) => {
				setSvgContent(svg);
			})
			.catch((error) => {
				console.error("Error fetching SVG:", error);
			});
	}, []);

	return (
		<StyledExpandButton
			className={`${isExpanded && "is-expanded"}`}
			data-stat-box-id={dataStatBoxId}
			onClick={onClick}>
			<span className="icon" dangerouslySetInnerHTML={{ __html: svgContent }}></span>
		</StyledExpandButton>
	);
};

const StyledExpandButton = styled.button`
	display: inline-flex;
	align-items: center;
	border: none;
	font-size: 0.875em;
	width: 2.5em;
	height: 2.5em;
	color: var(--color-muted-blue);
	background-color: var(--color-light-blue);
	border-radius: 100%;
	.icon {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		margin-left: 0.25;
		padding: 0.125em;
		svg {
			display: inline-flex;
			width: auto;
			height: 100%;
			fill: white;
			overflow: hidden;
			path {
				fill: white;
			}
		}
	}
	&.is-expanded {
		svg {
			.transform-handle {
				&:first-of-type {
					transform: rotate(180deg);
					transform-origin: 80% 20%;
				}
				&:last-of-type {
					transform: rotate(-180deg);
					transform-origin: 20% 80%;
				}
			}
		}
	}
`;
