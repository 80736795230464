import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

export const CTALink = ({ className, variants, svgIcon, href, title, target }) => {
	return (
		<StyledCtaAnchor
			variants={variants}
			initial="hidden"
			animate="visible"
			href={href}
			target={target}
			className={`${className}`}>
			{svgIcon && <span className="icon" dangerouslySetInnerHTML={{ __html: svgIcon }} />}
			<span className="text">{title}</span>
		</StyledCtaAnchor>
	);
};

const StyledCtaAnchor = motion(styled.a`
	transition: unset; // need to unset this one to let framer-motion do its thing
	border: 1px solid var(--color-light-blue) !important;
	&.fancy-btn {
		transition: unset; // its a stubborn one
	}
`);
