import React, { Children, useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { delay, motion, usePresence } from "framer-motion";
import MediaQuery, { useMediaQuery } from "react-responsive";

import { CalculatorContext } from "../templates/ImpactCalculator";
import { Title } from "../atoms/Title";
import { BorderBox } from "../atoms/BorderBox";
import { ExpandButton } from "../atoms/ExpandButton";
import { SubTitle } from "../atoms/SubTitle";

export const StatBox = ({ header, className = "", dollarAmount, fetchNewContent, onExpand, isExpanded }) => {
	const { value, sheetData } = useContext(CalculatorContext);
	const [agencyName, setAgencyName] = useState("");
	const [programName, setProgramName] = useState("");
	const [perk, setPerk] = useState("");
	const [isPresent, safeToRemove] = usePresence();
	const [contentWidth, setContentWidth] = useState("300px");
	const [contentHeight, setContentHeight] = useState("100%");
	const [boxExpansionState, setBoxExpansionState] = useState("regular");
	const [dimensionsState, setDimensionsState] = useState("hide");

	const placeholderStaBoxRef = useRef(null);

	const timeToExit = 750;
	const contentDivRef = useRef(null);

	const isDesktop = useMediaQuery({ query: "(min-width: 993px)" });

	const isTablet = useMediaQuery({ query: "(min-width: 768px) and (max-width: 992px)" });

	const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

	const setPlaceHolderStatBoxDimentions = (dimension = null) => {
		const placeholderStatBox = placeholderStaBoxRef.current;
		const contentDiv = contentDivRef.current;
		if (placeholderStatBox && contentDiv) {
			if (dimension == 0) {
				placeholderStatBox.style.width = "0px";
				placeholderStatBox.style.height = "0px";
			} else {
				const { width, height } = contentDiv.getBoundingClientRect();
				placeholderStatBox.style.width = `${width}px`;
				placeholderStatBox.style.height = `${height}px`;
			}
		}
	};

	const checkExpandedState = () => {
		console.log("checkExpandedState");
		console.log(`dollarAmount: ${dollarAmount} isExpanded: ${isExpanded}`);
		if (isExpanded) {
			setPlaceHolderStatBoxDimentions();
			setBoxExpansionState("expanded");
			setContentWidth("100%");
		} else {
			setPlaceHolderStatBoxDimentions(0);
			setBoxExpansionState("regular");
			if (isTablet) {
				setContentWidth("200px");
			} else if (isMobile) {
				setContentWidth("135px");
			}
		}
	};

	// const onExpand = () => {
	// 	setIsExpanded((prevIsExpanded) => {
	// 		const newIsExpanded = !prevIsExpanded;
	// 		// console.log("isExpanded", newIsExpanded ? "yes its expanded" : "no its not expanded");

	// 		if (newIsExpanded) {
	// 			setPlaceHolderStatBoxDimentions();
	// 			setBoxExpansionState("expanded");
	// 			setContentWidth("100%");
	// 		} else {
	// 			setPlaceHolderStatBoxDimentions(0);
	// 			setBoxExpansionState("regular");
	// 			if (isTablet) {
	// 				setContentWidth("200px");
	// 			} else if (isMobile) {
	// 				setContentWidth("135px");
	// 			}
	// 		}
	// 		return newIsExpanded;
	// 	});
	// };

	const filterDataBasedOnAmount = (sheetData) => {
		const filterName = `${dollarAmount}_dollar`;

		const filteredData = sheetData
			.map((row) => {
				const keyPropertiesToKeep = ["Agency Name", "Program Name", filterName];

				if (row.hasOwnProperty(filterName)) {
					const filteredRow = {};
					keyPropertiesToKeep.forEach((key) => {
						if (row.hasOwnProperty(key)) {
							filteredRow[key] = row[key];
						}
					});
					return filteredRow;
				}
				return null;
			})
			.filter((row) => row !== null);

		return filteredData;
	};

	const chooseRandomRow = (sheetData) => {
		const randomIndex = Math.floor(Math.random() * sheetData.length);
		const randomRow = sheetData[randomIndex];
		return randomRow;
		// return "test";
	};

	const freezeHeightDuringTransition = () => {
		const contentDiv = contentDivRef.current;
		const { height } = contentDiv.getBoundingClientRect();
		setContentHeight(height + "px");
	};

	const handleTransition = () => {
		let timeoutId;
		if (!isPresent) {
			freezeHeightDuringTransition();
			setDimensionsState("hide");
			timeoutId = setTimeout(() => {
				setContentHeight(0);
				safeToRemove();
			}, timeToExit);
			clearTimeout(timeoutId);
		} else {
			clearTimeout(timeoutId);
			setDimensionsState("show");
			setContentHeight("100%");
		}
	};

	const getRandomContent = () => {
		const filteredData = filterDataBasedOnAmount(sheetData);
		const randomRow = chooseRandomRow(filteredData);
		setPerk(randomRow[`${dollarAmount}_dollar`]);
		setAgencyName(randomRow["Agency Name"]);
		setProgramName(randomRow["Program Name"]);
	};

	useEffect(() => {
		if (!sheetData || sheetData.length === 0) return;
		handleTransition();
		getRandomContent();
		if (isDesktop) {
			setContentWidth("300px");
		}

		if (isTablet) {
			setContentWidth("200px");
		}

		if (isMobile) {
			setContentWidth("135px");
		}

		checkExpandedState();
	}, [isPresent, value, isExpanded]);

	const statBoxVariants = {
		regular: {
			width: "auto",
		},
		expanded: {
			width: "100%",
		},
	};

	const contentWrapperVariants = {
		hide: {
			width: 0,
			transition: {
				duration: 0.25,
				delay: 0.5,
				ease: "easeInOut",
			},
		},
		show: {
			width: contentWidth,
			transition: {
				duration: 0.25,
				delay: 0,
				ease: "easeInOut",
			},
		},
		expanded: {
			width: "100%",
			transition: {
				duration: 0,
			},
		},
	};

	return (
		<>
			<StyledStatBoxDiv
				className={`${className} ${isExpanded && "isExpanded"}`}
				variants={statBoxVariants}
				intitial="regular"
				animate={boxExpansionState}>
				<BorderBox
					isParentPresent={isPresent}
					borderAnimDelay={0.126}
					extraCheatPerimeterLength={200}
					isExpanded={isExpanded}
				/>
				<StyledContentWrapperDiv
					ref={contentDivRef}
					style={{ maxHeight: contentHeight }}
					variants={contentWrapperVariants}
					initial="hide"
					animate={dimensionsState}>
					<Title title={header} isParentPresent={isPresent} />
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.5 }}>
						<hr style={{ borderWidth: "2px" }} className="w-full" />
						{isPresent && (
							<>
								<div className="flex flex-col justify-between">
									<MediaQuery minWidth={993}>
										{/* <span className="font-bold text-mutedBlue">{agencyName}</span> */}
									</MediaQuery>
									<SubTitle title={programName}>{programName}</SubTitle>
								</div>
								<MediaQuery minWidth={993}>{perk !== "" && <motion.p>{perk}</motion.p>}</MediaQuery>
								<MediaQuery maxWidth={992}>
									{isExpanded ? (
										<motion.p className="mb-10">{perk}</motion.p>
									) : (
										<motion.p className="mt-0 mb-10"></motion.p>
									)}
									<div className="absolute left-0 right-0 bottom-0 mx-auto">
										<ExpandButton
											isExpanded={isExpanded}
											dataStatBoxId={dollarAmount}
											onClick={onExpand}
										/>
									</div>
								</MediaQuery>
							</>
						)}
					</motion.div>
				</StyledContentWrapperDiv>
			</StyledStatBoxDiv>
			<PlaceHolderStatBox className={`${isExpanded && "isExpanded"}`} ref={placeholderStaBoxRef} />
		</>
	);
};

const StyledStatBoxDiv = motion(styled.div`
	position: relative;
	display: flex;
	width: auto;
	height: auto;
	padding: 1em;
	&.isExpanded {
		z-index: 2;
		position: absolute;
		background-color: var(--color-dark-blue);
		top: 0;
		left: 0;
	}
	@media all and (max-width: 767px) {
		padding: 0.5em;
		p {
			font-size: 0.75rem;
		}
	}
`);

const PlaceHolderStatBox = styled.div`
	position: relative;
	width: 0;
`;

const StyledContentWrapperDiv = motion(styled.div`
	position: relative;
`);

const InnerStatBox = ({ isParentPresent, children, isExpanded }) => {
	const [animationState, setAnimationState] = useState("show");
	const timeoutIdRef = useRef(null);
	const [animateWidth, setAnimateWidth] = useState("300px");

	const isDesktop = useMediaQuery({ query: "(min-width: 993px)" }, undefined, (matches) => {
		matches && setAnimateWidth("300px");
	});

	const isTablet = useMediaQuery({ query: "(min-width: 768px) and (max-width: 992px)" }, undefined, (matches) => {
		matches && setAnimateWidth("200px");
	});

	const isMobile = useMediaQuery({ query: "(max-width: 767px)" }, undefined, (matches) => {
		matches && setAnimateWidth("135px");
	});

	useEffect(() => {
		if (!isParentPresent) {
			timeoutIdRef.current = setTimeout(() => {
				setAnimationState("exit");
			}, 500);
		} else {
			clearTimeout(timeoutIdRef.current);
			// clearTimeout(timeoutId);
			setAnimationState("show");
		}

		if (isDesktop) {
			setAnimateWidth("300px");
		}

		if (isTablet) {
			setAnimateWidth("200px");
		}

		if (isMobile) {
			if (!isExpanded) {
				setAnimateWidth("135px");
			} else {
				setAnimateWidth("100%");
			}
		}

		if (isExpanded) {
			setAnimateWidth("100%");
		}
	}, [isParentPresent, isExpanded]);

	const variants = {
		initial: { width: 0 },
		show: {
			width: animateWidth,
		},
		exit: { width: 0 },
	};

	return (
		<StyledInnerSTatBoxDiv
			className={`${isParentPresent && "isParentPresent"} ${animationState}`}
			variants={variants}
			initial="initial"
			animate={animationState}
			transition={{ duration: 0.25 }}>
			{Children.map(children, (child) => {
				return child; // Add return statement
			})}
		</StyledInnerSTatBoxDiv>
	);
};

const StyledInnerSTatBoxDiv = motion(styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 1em;
	/* width: 300px; */
	height: 100%;
	/* overflow: hidden; */
	@media all and (max-width: 767px) {
		padding: 0.5em;
	}
`);
